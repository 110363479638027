import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { IProductModuleElementsStyle } from "./ProductModule.types";

import ChangeProductQuantityB2B from "../../CartB2B/ChangeProductQuantityB2B";
import { TSnackbarReadoutValue } from "@ecp-redux/dto/themeSettings/themeSettings.types";

interface IAddToCartQuantityB2BProps {
  settings: IProductModuleElementsStyle["customActionAttributes"]["addToCartQuantity"];
  product: ISrpTransformResponseV2;
  snackbarValues: {
    successSnackbarStyle: `snackbar$$${number}` | TSnackbarReadoutValue;
    successSnackbarText: string;
    errorSnackbarStyle: `snackbar$$${number}` | TSnackbarReadoutValue;
    errorSnackbarText: string;
  };
}

const AddToCartQuantityB2B = ({
  product,
  settings,
  snackbarValues,
}: IAddToCartQuantityB2BProps) => {
  if (!product || !settings) return null;
  return (
    <ChangeProductQuantityB2B
      product={{
        sku: product.sku,
        deliveryQuantity: product.deliveryQuantity,
        unitOfMeasure: {
          currentUnitOfMeasure: product.mainUnitOfMeasurement,
          availableUnitsOfMeasure: product.alternativeUnitOfMeasures?.map(
            (unit) => ({
              unitOfMeasure: unit.auoM,
              unitOfMeasureLabel: unit.auoMLabel,
              unitOfMeasureQuantity: unit.value,
            })
          ),
        },
        productPriceDetails: {
          netValue: product.netPrice,
          grossValue: product.grossPrice,
        },
        price: product.basePrice,
        suggestedRetailPrice: product.suggestedPrice,
        concession: product.concession,
      }}
      minusButtonQuantityStyle={settings.removeFromCartButtonStyle}
      plusButtonQuantityStyle={settings.addToCartButtonStyle}
      inputQuantityStyle={settings.inputStyle}
      padding={settings.padding}
      alignment={settings.contentAlignment}
      changeProductQuantityMinusLabel="-"
      changeProductQuantityPlusLabel="+"
      isCart={false}
      snackbarValues={snackbarValues}
    />
  );
};
export default AddToCartQuantityB2B;
