import { useEffect, useRef, useState } from "react";
import { useObserveElementDimensions } from "../../../../../../global/components/ImageWrapper/ImageWrapper.methods";
import { TVariant } from "../../ProductVariants/ProductVariants.types";

export const useVariantsList = (variantSettings: TVariant) => {
  const {
    optionStyle,
    spacingElementsInRow,
    maxNumbersOfRows,
    label: { show: showLabel, name: labelText, style: labelStyle },
  } = variantSettings;
  const [wrapperRef, wrapperWidth] = useObserveElementDimensions();
  const elementRefs = useRef<HTMLButtonElement[]>([]);

  const [elementsInRow, setElementsInRow] = useState<HTMLButtonElement[][]>([]);

  useEffect(() => {
    if (!elementRefs.current?.[0] || !wrapperRef.current || !wrapperWidth)
      return;

    const elementWidths: number[] = [];
    const twoDimensionalArray: HTMLButtonElement[][] = [];
    let currentRow = 0;
    let rowWidthSum = 0;

    elementRefs.current.forEach(
      (buttonRef, index) =>
        buttonRef &&
        elementWidths.push(
          buttonRef.getBoundingClientRect().width + spacingElementsInRow
        )
    );

    elementWidths.forEach((width, index) => {
      if (rowWidthSum + width >= wrapperWidth) {
        currentRow++;
        rowWidthSum = 0;
      }
      if (!twoDimensionalArray[currentRow]) {
        twoDimensionalArray[currentRow] = [];
      }

      twoDimensionalArray[currentRow].push(elementRefs.current[index]);
      rowWidthSum += width;
    });

    setElementsInRow(twoDimensionalArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperWidth]);

  return {
    wrapperRef,
    elementRefs,
    wrapperWidth,
    elementsInRow,
    showLabel,
    labelText,
    optionStyle,
    labelStyle,
    maxNumbersOfRows,
  };
};
