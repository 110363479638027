import styled from "styled-components";
import { alignmentOptionsToValue } from "../../../../../../settingsPatterns/composeCss";
import { TVariant } from "../../ProductVariants/ProductVariants.types";

export const StyledVariantsContainer = styled.div<{
  $variantSettings: TVariant;
  $showDefaultLabelButton: boolean;
}>(({ $variantSettings, $showDefaultLabelButton }) => {
  const {
    rowWidth,
    spacingBetweenRows,
    spacingElementsInRow,
    variantsAlignment,
  } = $variantSettings;

  return `
      display: flex;
      justify-content: ${alignmentOptionsToValue(variantsAlignment)};
      flex-wrap: wrap;
      width: ${rowWidth}%;
      gap: ${spacingBetweenRows}px ;
      margin-bottom: ${spacingBetweenRows}px;

      .variants-list {
        &__element {
          opacity: 0
        }

        &__sorted-list {
          display: flex;
          gap: ${spacingElementsInRow}px;
        }

        &__label_element {
          display: ${$showDefaultLabelButton ? "flex" : "none"};
        }
      }
    `;
});
