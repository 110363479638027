import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { IListingTrackerEvent, ISiteType } from "../schema";
import { eventListingTracker } from "../events";
import { isEqual } from "lodash";
import { extractCategoryFromPath, mapProductsSRPtoEvent } from "../helpers";
import { TEventOrigin } from "../const";

interface IUseListingTrackerEvent {
  products: object[];
  origin: TEventOrigin;
  deps: unknown[];
}

export const useListingTrackerEvent = ({
  origin,
  deps,
  products,
}: IUseListingTrackerEvent) => {
  const { getSessionStorageItem } = useSetSessionStorage();
  const prevListingTrackerEvent = useRef<Partial<IListingTrackerEvent>>({});
  const router = useRouter();

  useEffect(() => {
    if (products?.length > 0) {
      const email = getSessionStorageItem("email");
      const customerId =
        parseInt(getSessionStorageItem("customer_id") as string) || null;
      const siteType = getSessionStorageItem("site_type") as ISiteType;

      const listingTrackerEvent: Partial<IListingTrackerEvent> = {
        products: mapProductsSRPtoEvent(products),
        email,
        customer_id: customerId,
        site_type: siteType,
        category_id: extractCategoryFromPath(router.asPath),
        origin,
      };

      if (!isEqual(prevListingTrackerEvent.current, listingTrackerEvent)) {
        eventListingTracker(listingTrackerEvent);
        prevListingTrackerEvent.current = listingTrackerEvent;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};
