import React, { useEffect } from "react";
import {
  IGlobalDropdownObject,
  TDropdownReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isPortalSide } from "../../helpers/helpers";
import { findCartLineBySku } from "../../shared/components/domain/CartB2B/cartB2B.methods";
import { useCartB2BContext } from "../../shared/components/domain/CartB2B/useCartB2BContext";
import ThemeStyledDropdown from "../../shared/styleElements/StyledDropdown/ThemeStyledDropdown";
import { useCommunicationBetweenBoxesContext } from "../../structure/Contexts/CommunicationBetweenBoxes";
import { IBoxB2BAddToCartUnitSettings } from "./BoxB2BAddToCartUnit.types";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";

interface IB2BAddToCartUnitProps {
  settings: IBoxB2BAddToCartUnitSettings;
  product: ISrpTransformResponseV2 | null;
  customStyle?: TDropdownReadoutValue["custom"];
}

const B2BAddToCartUnit: React.FC<IB2BAddToCartUnitProps> = ({
  settings,
  product,
  customStyle,
}) => {
  const { cartData } = useCartB2BContext();
  const { chosenUnit, setChosenUnit } = useCommunicationBetweenBoxesContext();

  useEffect(() => {
    if (chosenUnit === "" && product)
      setChosenUnit(product.mainUnitOfMeasurement);
  }, [product]);

  const cartLine = findCartLineBySku(
    cartData,
    product
      ? {
          sku: product.sku,
          unitOfMeasure: {
            currentUnitOfMeasure: product.mainUnitOfMeasurement,
            availableUnitsOfMeasure: [
              ...(product.alternativeUnitOfMeasures?.map((unit) => ({
                unitOfMeasure: unit.auoM,
                unitOfMeasureLabel: unit.auoMLabel,
                unitOfMeasureQuantity: unit.value,
              })) ?? []),
              {
                unitOfMeasure: product.mainUnitOfMeasurement,
                unitOfMeasureLabel: product.mainUnitOfMeasurementLabel,
                unitOfMeasureQuantity: 1,
              },
            ],
          },
        }
      : undefined
  );

  const dropdownItems = cartLine
    ? cartLine.unitOfMeasure.availableUnitsOfMeasure.map(
        ({ unitOfMeasure, unitOfMeasureLabel, unitOfMeasureQuantity }) => ({
          unitOfMeasure: unitOfMeasure,
          unitOfMeasureLabel:
            unitOfMeasure === cartLine.unitOfMeasure.baseUnitOfMeasure
              ? unitOfMeasureLabel
              : `${unitOfMeasureLabel} (${unitOfMeasureQuantity})`,
        })
      )
    : [];

  if (product === null) {
    return isPortalSide() ? null : <div>No content to display</div>;
  }

  return (
    <ThemeStyledDropdown
      style={{
        id: settings.b2b_add_to_cart_unit_dropdown_style,
        custom: customStyle ?? ({} as IGlobalDropdownObject),
      }}
      data-testid="b2b-unit-chooser"
      items={dropdownItems.map((item) => ({
        id: item.unitOfMeasure,
        label: item.unitOfMeasureLabel,
      }))}
      selectedItemId={chosenUnit}
      onClickItem={(id) => setChosenUnit(id)}
    />
  );
};

export default Memo(B2BAddToCartUnit);
