import ProductAttributesDisplay from "./ProductAttributesDisplay";
import { StyledAttributesInRow, StyledRow } from "./ProductModule.styled";
import { IAttributesInRowProps } from "./ProductModule.types";

const AttributesInRow: React.FC<IAttributesInRowProps> = (props) => {
  const {
    attributesRows,
    product,
    productSettings,
    orderInModule,
    boxId,
    boxType,
    variantSettings,
    buttonText,
    ratingText,
    moduleSettings,
    attributesLineDetection,
  } = props;

  return (
    <StyledAttributesInRow
      className="attributes-in-row-container"
      spaceBetweenTextElements={moduleSettings.spaceBetweenTextElements}
      button={{
        custom: productSettings.button?.isCustomWidth ?? false,
        customWidth: productSettings.button?.customWidth ?? 0,
      }}
      customButton={{
        custom:
          productSettings.customActionAttributes?.customButton?.isCustomWidth ??
          false,
        customWidth:
          productSettings.customActionAttributes?.customButton?.customWidth ??
          0,
      }}
      secondaryButton={{
        custom: productSettings.secondaryButton?.isCustomWidth || false,
        customWidth: productSettings.secondaryButton?.customWidth || 0,
      }}
    >
      {attributesRows.map((row, rowIndex) => {
        const splittedAttributes = row.split(";");
        const attributes = splittedAttributes.filter((attr) =>
          orderInModule.includes(attr)
        );

        if (row.length !== 0) {
          return (
            <StyledRow
              className={`attributes-in-row-container__row__${rowIndex}`}
              rowIndex={rowIndex}
              key={rowIndex}
              alignment={moduleSettings.alignment}
            >
              {attributes.map((attr, safeIndex) => {
                return (
                  <ProductAttributesDisplay
                    key={attr}
                    attribute={attr}
                    productSettings={productSettings}
                    product={product}
                    boxId={boxId}
                    boxType={boxType}
                    safeIndex={safeIndex}
                    buttonText={buttonText}
                    ratingText={ratingText}
                    variantSettings={variantSettings}
                    attributesLineDetection={attributesLineDetection}
                  />
                );
              })}
            </StyledRow>
          );
        }
      })}
    </StyledAttributesInRow>
  );
};

export default AttributesInRow;
