import React from "react";
import { useTheme } from "styled-components";
import {
  IThemeState,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";

const StarIcon: React.FC<{ fill: TColorReadoutValue }> = ({ fill }) => {
  const { colorPalette } = useTheme() as IThemeState;
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={convertColorIdToHex(fill, colorPalette)}
    >
      <path d="M9 0.125L6.44063 5.31125L0.720001 6.13812L4.86 10.1769L3.88125 15.875L9 13.1862L14.1188 15.875L13.14 10.1769L17.28 6.14375L11.5594 5.31125L9 0.125Z" />
    </svg>
  );
};

export default StarIcon;
