import React from "react";
import { useTheme } from "styled-components";
import {
  IThemeState,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";

const StarCheckedIcon: React.FC<{ fill: TColorReadoutValue }> = ({ fill }) => {
  const { colorPalette } = useTheme() as IThemeState;
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={convertColorIdToHex(fill, colorPalette)}
    >
      <path d="M8.99997 0.125L6.4406 5.31125L0.719971 6.13812L4.85997 10.1769L3.88122 15.875L8.99997 13.1862L14.1187 15.875L13.14 10.1769L17.28 6.14375L11.5593 5.31125L8.99997 0.125Z" />
    </svg>
  );
};

export default StarCheckedIcon;
