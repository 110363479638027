import styled from "styled-components";

export const StyledProductRating = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .product-rating {
    &__star-container {
      display: flex;
      align-items: center;
      margin-left: 0.25rem;
    }
  }
`;
