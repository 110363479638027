import StyledText from "../../../../styleElements/StyledText/StyledText";
import { StyledPrefixSuffix } from "./ProductModule.styled";
import { IPrefixSuffixContainerProps } from "./ProductModule.types";

const PrefixSuffixContainer: (
  props: IPrefixSuffixContainerProps
) => JSX.Element = ({ productSettings, child }) => {
  if (productSettings === undefined) {
    return <>{child}</>;
  }

  return (
    <StyledPrefixSuffix productSettings={productSettings}>
      {productSettings.prefix.show &&
        productSettings.prefix.heading &&
        productSettings.show && (
          <StyledText
            $settings={productSettings.prefix}
            className="prefix-suffix"
          >
            {productSettings.prefix.heading}
          </StyledText>
        )}
      {child}
      {productSettings.suffix.show &&
        productSettings.suffix.heading &&
        productSettings.show && (
          <StyledText
            $settings={productSettings.suffix}
            className="prefix-suffix"
          >
            {productSettings.suffix.heading}
          </StyledText>
        )}
    </StyledPrefixSuffix>
  );
};

export default PrefixSuffixContainer;
