import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../../../../settingsPatterns/settingsPatterns.methods";
import { Shape } from "./BadgesList";

export const StyledBadgesList = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: var(--zindex-badges-list);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledBadgeContent = styled.div<{
  backgroundColorId: TColorId;
  shape: Shape;
}>(({ backgroundColorId, shape, theme }) => {
  const bgcColor = convertColorIdToHex(backgroundColorId, theme.colorPalette);

  return `
    background: ${bgcColor};
    padding: 0 4px;
    margin-bottom: 4px;
    text-align: center;

    .badge__name {
      cursor: default;
    }

    ${
      shape === Shape.CIRCLE
        ? `
        aspect-ratio: 1/1;
        border-radius: 50%;
        display:grid;
        place-items: center;
        `
        : ""
    }
`;
});
